import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="xl" mb={2}>
        Oops! Something went wrong.
      </Heading>
      <Text color={'gray.500'} mb={6}>
        We're sorry for the inconvenience. Our team has been notified and is working on a fix.
      </Text>
      <Text color={'red.500'} mb={6}>
        Error: {error.message}
      </Text>
      <Button
        colorScheme="blue"
        onClick={resetErrorBoundary}
        mb={6}
      >
        Try again
      </Button>
      <Text fontSize="sm">
        If the problem persists, please contact our support team.
      </Text>
    </Box>
  );
};

export default ErrorFallback;
