import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import { ChakraProvider, Box, VStack, HStack, Button, Flex, useColorMode, useColorModeValue, IconButton, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Container, Spinner, Divider, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { MoonIcon, SunIcon, SearchIcon, RepeatIcon, CloseIcon, HamburgerIcon, AddIcon, StarIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { FaHome as HomeIcon, FaVolumeUp, FaList, FaPlay, FaFolder } from 'react-icons/fa';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './App.css';
import UserIcon from './components/UserIcon';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorFallback from './components/ErrorFallback';
import CookieConsent from 'react-cookie-consent';

const PlaylistManager = lazy(() => import('./components/Playlist'));
const Login = lazy(() => import('./components/Login'));
const Signup = lazy(() => import('./components/Signup'));
const Comment = lazy(() => import('./components/Comment'));
const Blog = lazy(() => import('./components/Blog'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const Home = lazy(() => import('./components/Home'));
const AudioPlayer = lazy(() => import('./components/AudioPlayer'));

function Sidebar({ isLoggedIn, isOpen, onClose, playlists, folders, onFolderSelect }) {
  const bgColor = useColorModeValue('gray.900', 'gray.100');
  const buttonHoverBg = useColorModeValue('gray.800', 'gray.200');
  const buttonColor = useColorModeValue('white', 'gray.800');

  return (
    <Box
      as="nav"
      width={{ base: "100%", md: "240px" }}
      height="100vh"
      bg={bgColor}
      color={buttonColor}
      p={4}
      position={{ base: "fixed", md: "static" }}
      top={0}
      left={0}
      zIndex={20}
      overflowY="auto"
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      transition="0.3s ease"
      boxShadow={{ base: "2px 0 10px rgba(0,0,0,0.2)", md: "none" }}
    >
      <VStack align="stretch" spacing={6}>
        <Flex justify="space-between" align="center" mb={4}>
          <LazyLoadImage
            src="/kristian-vuljar-logo.png"
            alt="Kristian Vuljar Music"
            width={{ base: "60px", md: "100px" }}
            height={{ base: "60px", md: "100px" }}
            effect="blur"
            placeholderSrc="/kristian-vuljar-placeholder.png"
            onError={(e) => {
              console.error('Error loading logo:', e);
              e.target.src = "/kristian-vuljar-placeholder.png";
            }}
          />
          <IconButton
            icon={<CloseIcon />}
            onClick={onClose}
            display={{ base: "flex", md: "none" }}
            variant="ghost"
            color={buttonColor}
            _hover={{ bg: buttonHoverBg }}
            aria-label="Close menu"
          />
        </Flex>
        <Button leftIcon={<HomeIcon />} justifyContent="flex-start" variant="ghost" w="100%" color={buttonColor} _hover={{ bg: buttonHoverBg }}>Home</Button>
        <Button leftIcon={<SearchIcon />} justifyContent="flex-start" variant="ghost" w="100%" color={buttonColor} _hover={{ bg: buttonHoverBg }}>Search</Button>
        <Button leftIcon={<RepeatIcon />} justifyContent="flex-start" variant="ghost" w="100%" color={buttonColor} _hover={{ bg: buttonHoverBg }}>Your Library</Button>
        {isLoggedIn && (
          <>
            <Button leftIcon={<AddIcon />} justifyContent="flex-start" variant="ghost" w="100%" color={buttonColor} _hover={{ bg: buttonHoverBg }}>Create Playlist</Button>
            <Button leftIcon={<StarIcon />} justifyContent="flex-start" variant="ghost" w="100%" color={buttonColor} _hover={{ bg: buttonHoverBg }}>Liked Songs</Button>
            <Divider my={2} />
            <Accordion allowToggle>
              <AccordionItem border="none">
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontWeight="bold">Folders</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {folders.map((folder) => (
                    <Button
                      key={folder}
                      leftIcon={<FaFolder />}
                      justifyContent="flex-start"
                      variant="ghost"
                      w="100%"
                      color={buttonColor}
                      _hover={{ bg: buttonHoverBg }}
                      onClick={() => onFolderSelect(folder)}
                    >
                      {folder}
                    </Button>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <VStack align="stretch" spacing={2} maxHeight="300px" overflowY="auto">
              {playlists.map((playlist) => (
                <Button
                  key={playlist.id}
                  justifyContent="flex-start"
                  variant="ghost"
                  w="100%"
                  color={buttonColor}
                  _hover={{ bg: buttonHoverBg }}
                >
                  {playlist.name}
                </Button>
              ))}
            </VStack>
          </>
        )}
      </VStack>
    </Box>
  );
}

function Player() {
  const bgColor = useColorModeValue('gray.900', 'gray.100');
  const textColor = useColorModeValue('white', 'gray.800');
  const sliderColor = useColorModeValue('green.500', 'green.200');
  const iconColor = useColorModeValue('gray.300', 'gray.600');

  return (
    <Box as="footer" p={2} bg={bgColor} borderTop="1px" borderColor={useColorModeValue('gray.800', 'gray.200')}>
      <Flex justify="space-between" align="center">
        <HStack spacing={4} flex={1} maxWidth="300px">
          <LazyLoadImage
            src="/kristian-vuljar-album-cover.jpg"
            alt="Album Cover"
            width={56}
            height={56}
            effect="blur"
            placeholderSrc="/kristian-vuljar-placeholder.png"
            onError={(e) => {
              console.error('Error loading album cover:', e);
              e.target.src = "/kristian-vuljar-placeholder.png";
            }}
            style={{ objectFit: 'cover' }}
          />
          <Box>
            <Text fontWeight="bold" fontSize="sm" color={textColor}>Song Title</Text>
            <Text fontSize="xs" color={useColorModeValue('gray.400', 'gray.500')}>Kristian Vuljar</Text>
          </Box>
          <IconButton icon={<StarIcon />} aria-label="Like" variant="ghost" size="sm" color={iconColor} />
        </HStack>
        <VStack spacing={1} flex={2} maxWidth="600px">
          <HStack spacing={4} width="100%" justifyContent="center">
            <IconButton icon={<RepeatIcon />} aria-label="Shuffle" variant="ghost" size="sm" color={iconColor} />
            <IconButton icon={<ArrowLeftIcon />} aria-label="Previous" variant="ghost" size="sm" color={iconColor} />
            <IconButton icon={<FaPlay />} aria-label="Play/Pause" variant="solid" size="md" borderRadius="full" bg={sliderColor} color={bgColor} _hover={{ bg: sliderColor }} />
            <IconButton icon={<ArrowRightIcon />} aria-label="Next" variant="ghost" size="sm" color={iconColor} />
            <IconButton icon={<RepeatIcon />} aria-label="Repeat" variant="ghost" size="sm" color={iconColor} />
          </HStack>
          <HStack width="100%" spacing={4}>
            <Text fontSize="xs" color={iconColor}>0:00</Text>
            <Slider aria-label="playback-progress" defaultValue={30} flex={1}>
              <SliderTrack bg={useColorModeValue('gray.700', 'gray.300')}>
                <SliderFilledTrack bg={sliderColor} />
              </SliderTrack>
              <SliderThumb boxSize={3} bg={sliderColor} />
            </Slider>
            <Text fontSize="xs" color={iconColor}>3:30</Text>
          </HStack>
        </VStack>
        <HStack spacing={4} flex={1} maxWidth="300px" justifyContent="flex-end">
          <IconButton icon={<FaList />} aria-label="Queue" variant="ghost" size="sm" color={iconColor} />
          <IconButton icon={<FaVolumeUp />} aria-label="Volume" variant="ghost" size="sm" color={iconColor} />
          <Box width="100px">
            <Slider aria-label="volume-control" defaultValue={30}>
              <SliderTrack bg={useColorModeValue('gray.700', 'gray.300')}>
                <SliderFilledTrack bg={sliderColor} />
              </SliderTrack>
              <SliderThumb boxSize={3} bg={sliderColor} />
            </Slider>
          </Box>
        </HStack>
      </Flex>
    </Box>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedSongId, setSelectedSongId] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const color = useColorModeValue('gray.800', 'white');
  const [playlists, setPlaylists] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const navigate = useNavigate();

  const handleSignup = (userData) => {
    console.log('User signed up:', userData);
    navigate('/login');
  };

  const authAxios = axios.create({
    baseURL: 'http://localhost:8000',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`
    }
  });

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const response = await axios.post('http://localhost:8000/refresh', { refresh_token: refreshToken });
      const { access_token, refresh_token } = response.data;
      localStorage.setItem('authToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      return access_token;
    } catch (error) {
      console.error('Error refreshing token:', error);
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      setIsLoggedIn(false);
      navigate('/login');
      throw error;
    }
  };

  // Implement caching mechanism for API calls
  authAxios.interceptors.request.use(async config => {
    const cachedResponse = localStorage.getItem(config.url);
    if (cachedResponse) {
      const { data, timestamp } = JSON.parse(cachedResponse);
      if (Date.now() - timestamp < 5 * 60 * 1000) { // 5 minutes cache
        return Promise.resolve({ data, headers: {}, config, status: 200 });
      }
    }
    return config;
  });

  authAxios.interceptors.response.use(
    response => {
      localStorage.setItem(response.config.url, JSON.stringify({
        data: response.data,
        timestamp: Date.now()
      }));
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newToken = await refreshToken();
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return authAxios(originalRequest);
        } catch (refreshError) {
          console.error('Failed to refresh token:', refreshError);
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsLoggedIn(true);
      fetchPlaylists();
      fetchFolders();
    }
  }, []);

  const fetchPlaylists = async () => {
    try {
      const response = await authAxios.get('/playlists/');
      setPlaylists(response.data);
    } catch (err) {
      console.error('Failed to fetch playlists:', err);
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await authAxios.get('/songs/');
      const uniqueFolders = [...new Set(response.data.map(song => song.folder))];
      setFolders(uniqueFolders);
    } catch (err) {
      console.error('Failed to fetch folders:', err);
    }
  };

  const handleLogin = (tokens) => {
    localStorage.setItem('authToken', tokens.access_token);
    localStorage.setItem('refreshToken', tokens.refresh_token);
    setIsLoggedIn(true);
    navigate('/');
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const handleSongSelect = (songId) => {
    console.log('Selected song ID:', songId);
    setSelectedSongId(songId);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <ChakraProvider>
        <Flex direction="column" minHeight="100vh" bg={bgColor} color={color}>
          <Flex
            as="header"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="0.5rem 1rem"
            bg={useColorModeValue('gray.100', 'gray.900')}
            borderBottom="1px"
            borderColor={useColorModeValue('gray.200', 'gray.700')}
          >
            <Flex align="center" mr={5}>
              <LazyLoadImage
                src="/kristian-vuljar-logo.png"
                alt="Kristian Vuljar Music"
                width={40}
                height={40}
                effect="blur"
                placeholderSrc="/kristian-vuljar-placeholder.png"
                onError={(e) => {
                  console.error('Error loading logo:', e);
                  e.target.src = "/kristian-vuljar-logo.png";
                }}
              />
            </Flex>

            <HStack spacing={4} display={{ base: "none", md: "flex" }}>
              <Link to="/">Home</Link>
              {isLoggedIn ? (
                <Button onClick={handleLogout} variant="ghost">Logout</Button>
              ) : (
                <Link to="/signup">
                  <Button variant="ghost">Sign up</Button>
                </Link>
              )}
              <IconButton
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                variant="ghost"
                aria-label="Toggle color mode"
                ml={2}
              />
            </HStack>

            <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={toggleMobileMenu}
              icon={<HamburgerIcon />}
              variant="outline"
              aria-label="Toggle mobile menu"
            />
          </Flex>

          <Flex flex={1}>
            <Sidebar
              isLoggedIn={isLoggedIn}
              isOpen={showMobileMenu}
              onClose={toggleMobileMenu}
              playlists={playlists}
              folders={folders}
              onFolderSelect={handleFolderSelect}
            />
            <Box
              flex={1}
              p={4}
              overflowY="auto"
              ml={{ base: 0, md: "240px" }}
              transition="margin-left 0.3s"
            >
              <Container maxW="container.xl">
                <ErrorBoundary fallback={<ErrorFallback />}>
                  <Suspense fallback={<Spinner size="xl" />}>
                    <Routes>
                      <Route path="/" element={
                        <Home
                          isLoggedIn={isLoggedIn}
                          onSongSelect={handleSongSelect}
                          selectedSongId={selectedSongId}
                          selectedFolder={selectedFolder}
                          folders={folders}
                        />
                      } />
                      <Route path="/login" element={<Login onLogin={handleLogin} navigate={navigate} />} />
                      <Route path="/signup" element={<Signup onSignup={handleSignup} navigate={navigate} />} />
                      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                      <Route path="/songs/:songPath/stream" element={
                        <AudioPlayer
                          url={`http://localhost:8000/songs/${encodeURIComponent(selectedSongId)}/stream`}
                          title={selectedSongId ? `Now playing: ${selectedSongId}` : 'Select a song to play'}
                        />
                      } />
                      <Route path="/streaming" element={
                        <AudioPlayer
                          url={`http://localhost:8000/songs/${encodeURIComponent(selectedSongId)}/stream`}
                          title={selectedSongId ? `Now playing: ${selectedSongId}` : 'Select a song to play'}
                        />
                      } />
                      <Route path="/blog" element={<Blog />} />
                    </Routes>
                  </Suspense>
                </ErrorBoundary>
              </Container>
            </Box>
          </Flex>
          <Player />
        </Flex>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="kristianVuljarMusicCookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience. By continuing to use this site, you agree to our use of cookies.{" "}
          <a href="/privacy-policy" style={{ textDecoration: "underline" }}>Learn more</a>
        </CookieConsent>
      </ChakraProvider>
  );
}

export default App;
